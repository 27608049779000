import * as React from 'react';

import { HeroContentHome, Text, Box, AppSummaryCard, Seo } from '../components';
import MainLayout from '../layouts/MainLayout';
import QuoraPixelLogo from '../assets/images/sapp-quora-pixel.inline.svg';
import SAPPSubscriptionsLogo from '../assets/images/sapp-subscriptions.inline.svg';
import SAPPReorderLogo from '../assets/images/sapp-reorder-logo.inline.svg';

const BlogIndex = ({ data, location }) => {
  return (
    <MainLayout heroContent={<HeroContentHome />}>
      <Seo title="SAPP" />
      <Box bg="light1" px={[0, 100]} py={50} id="apps">
        <Text variant="h2" textAlign="center" mb={4}>
          SAPP Apps
        </Text>
        <Box
          display="flex"
          flexWrap="wrap"
          mx={[0, -3]}
          justifyContent="center"
        >
          <Box width={[1, 1, 1, 1 / 2]} p={[4, 3]}>
            <AppSummaryCard
              appName="SAPP Quora Pixel"
              summary="Simple, hassle-free, easy to use Quora Pixel app that allows you to track customer's each and every activity. A comprehensive tool that you can use to boost your marketing efficiency. Now, be more specific and personalize in all your marketing effort to find your customer and meet their demand. Enjoy this useful one-click installable tool that comes with the free support of our team."
              url="/apps/quorapixel/"
              AppLogo={QuoraPixelLogo}
            />
          </Box>
          <Box width={[1, 1, 1, 1 / 2]} p={[4, 3]}>
            <AppSummaryCard
              appName="SAPP Subscriptions"
              summary="SAPP Subscriptions is a comprehensive subscription solution to create recurring billing options on your Shopify store. Our app allows customers to purchase your products or services on a recurring basis without needing to manually visit the store every time."
              url="/apps/sapp-subscriptions/"
              AppLogo={SAPPSubscriptionsLogo}
            />
          </Box>
          <Box width={[1, 1, 1, 1 / 2]} p={[4, 3]}>
            <AppSummaryCard
              appName="SAPP Reorder"
              summary="SAPP Reorder allows Shopify merchants to add a Reorder button next to each order in their customer's accounts. This button enables customers to reorder with customization: add or remove items and modify the quantity. Our app streamlines the reordering process, making it convenient for customers, and helps merchants earn customer loyalty. Merchants can also customize the design and text of the reorder button to match their store's branding and offer discounts on reorders to increase sales."
              url="/apps/sapp-reorder/"
              AppLogo={SAPPReorderLogo}
            />
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default BlogIndex;
